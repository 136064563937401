import { ICarouselProps } from './types';
import Slider from 'react-slick';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useStyles from './styles';
import classnames from 'classnames';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';

const StyledUl = styled.ul`
  li {
    width: 18px !important;
    div {
      background-color: #d9d9d9 !important;
    }
  }

  .slick-active {
    div {
      background-color: #4a3ab1 !important;
    }
  }
      
  }
    `;

function NextArrow(props: any) {
  const { className, onClick, wapperClassName } = props;
  return (
    <div className={classnames(className, wapperClassName)} onClick={onClick} />
  );
}

function PrevArrow(props: any) {
  const { className, onClick, wapperClassName } = props;
  return (
    <div className={classnames(className, wapperClassName)} onClick={onClick} />
  );
}

const Carousel: React.FunctionComponent<ICarouselProps> = ({
  children,
  settings,
  backgroundShadow = true,
  type = 'primary',
  title,
  noArrows = false,
  beforeChangeHandler = (curr: any, next: any) => {},
  afterChangeHandler = (curr: any, next: any) => {},
  classes: propClasses = {},
  ...props
}) => {
  const isDesktop = useMediaQuery('(min-device-width: 767px)');
  const classes = useStyles({ backgroundShadow, isDesktop });

  const localSettings = {
    dots: settings?.dots || true,
    lazyLoad: settings?.lazyLoad ? settings?.lazyLoad : true,
    infinite: settings?.infinite ? settings?.infinite : true,
    speed: settings?.speed ? settings?.speed : 300,
    slidesToShow: settings?.slidesToShow ? settings?.slidesToShow : 3,
    slidesToScroll: settings?.slidesToScroll ? settings?.slidesToScroll : 1,
    autoplay: settings?.autoplay ? settings?.autoplay : false,
    swipeToSlide: settings?.swipeToSlide ? settings?.swipeToSlide : true,
    appendDots: props?.CustomDots
      ? props?.CustomDots
      : settings?.dots
      ? (dots: any) => (
          <div>
            <StyledUl style={{ margin: '0px' }}> {dots} </StyledUl>
          </div>
        )
      : (dots: any) => <></>,
    customPaging: props?.CustomPaging
      ? props?.CustomPaging
      : function (i: any) {
          return (
            <a>
              <div className={classnames(classes.dots, propClasses.dots)}></div>
            </a>
          );
        },
    // adaptiveHeight: true,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          swipeToSlide: true,
        },
      },
    ],
    dotsClass: `slick-dots slick-thumb ${
      props?.CustomPaging ? null : classes.slicksPadding
    }`,

    nextArrow: noArrows ? (
      <></>
    ) : (
      <NextArrow
        wapperClassName={classnames(
          classes.slickNextNew,
          propClasses.slickNextNew
        )}
      />
    ),
    prevArrow: noArrows ? (
      <></>
    ) : (
      <PrevArrow
        wapperClassName={classnames(
          classes.slickPrevNew,
          propClasses.slickPrevNew
        )}
      />
    ),
    beforeChange: (current: any, next: any) =>
      beforeChangeHandler(current, next),
    afterChange: (current: any, next: any) => afterChangeHandler(current, next),

    ...settings,
  };

  return (
    <div
      className={classnames(
        classes.carousalMainContainer,
        propClasses.carousalMainContainer
      )}
    >
      {props?.HeaderComponent || (
        <div className={classnames(classes.header, propClasses.header)}>
          {title}
        </div>
      )}
      <div
        className={classnames(
          classes.carousalWrapper,
          propClasses.carousalWrapper
        )}
      >
        <Slider {...localSettings}>{children}</Slider>
      </div>
    </div>
  );
};
export default withGenerateClassName(Carousel);
