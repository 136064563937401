import { makeStyles } from '@material-ui/core';

interface StyleProps {
  size?: 'medium' | 'large';
}

export const useStyles = makeStyles(
  (theme: any) => ({
    linkRoot: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: theme?.palette?.secondary?.main,
      fontSize: ({ size }: StyleProps) => (size === 'large' ? '16px' : '14px'),
      cursor: 'pointer',
    },
    underlineAlways: {
      color: theme?.palette?.primary?.main,
    },
    underlineHover: {
      '&:hover': {
        color: theme?.palette?.primary?.main,
      },
    },
  }),
  { name: 'ComponentLibrary' }
);
