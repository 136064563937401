import React from 'react';
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import { Box, CircularProgress } from '@material-ui/core';

interface IStyles extends ButtonProps {
  disabled?: boolean;
  fullWidth?: boolean;
}

const useStyles: any = makeStyles(
  (globaltheme: any) => ({
    button: {
      fontSize: '16px',
      lineHeight: '16px',
      textTransform: 'none',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:disabled': {
        opacity: ({ loading }: IStyles) => (loading ? '1' : '0.5'),
      },
    },
    buttonWrapper: {
      cursor: ({ disabled }: IStyles) => (disabled ? 'not-allowed' : 'pointer'),
      width: ({ fullWidth }: IStyles) => (fullWidth ? '100%' : ''),
      position: 'relative',
    },
    primary: {
      backgroundColor: globaltheme?.palette?.primary?.main,
      color: globaltheme?.palette?.basic?.white,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.primary?.shade_600,
      },
      '&:disabled': {
        backgroundColor: `${globaltheme?.palette?.primary?.main} !important`,
        color: globaltheme?.palette?.basic?.white,
      },
    },
    secondary: {
      color: globaltheme?.palette?.primary?.main,
      border: `1.5px solid ${globaltheme?.palette?.primary?.main}`,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.primary?.main,
        color: globaltheme?.palette?.basic?.white,
      },
      '&:disabled': {
        color: globaltheme?.palette?.primary?.main,
        border: `1.5px solid ${globaltheme?.palette?.primary?.main}`,
        backgroundColor: globaltheme?.palette?.basic?.white,
      },
    },
    tertiary: {
      color: globaltheme?.palette?.secondary?.main,
      border: `1.5px solid ${globaltheme?.palette?.secondary?.main}`,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.secondary?.shade_100,
        border: 'none',
      },
      '&:disabled': {
        border: `1.5px solid ${globaltheme?.palette?.secondary?.main}`,
        color: globaltheme?.palette?.secondary?.main,
        backgroundColor: globaltheme?.palette?.basic?.white,
      },
    },
    success: {
      backgroundColor: globaltheme?.palette?.fine_pine?.main,
      color: globaltheme?.palette?.basic?.white,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.fine_pine?.shade_700,
      },
      '&:disabled': {
        color: globaltheme?.palette?.basic?.white,
        backgroundColor: `${globaltheme?.palette?.fine_pine?.main} !important`,
      },
    },
    danger: {
      backgroundColor: globaltheme?.palette?.basic?.danger_red,
      color: globaltheme?.palette?.basic?.white,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.coral_red?.shade_600,
      },
      '&:disabled': {
        backgroundColor: `${globaltheme?.palette?.basic?.danger_red} !important`,
        color: globaltheme?.palette?.basic?.white,
      },
    },
    warning: {
      backgroundColor: globaltheme?.palette?.butterscotch_yellow?.main,
      color: globaltheme?.palette?.basic?.white,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.butterscotch_yellow?.shade_600,
      },
      '&:disabled': {
        backgroundColor: `${globaltheme?.palette?.butterscotch_yellow?.main} !important`,
        color: globaltheme?.palette?.basic?.white,
      },
    },
    subtlePrimary: {
      backgroundColor: globaltheme?.palette?.basic?.white,
      color: globaltheme?.palette?.primary?.main,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.primary?.shade_100,
      },
      '&:disabled': {
        backgroundColor: globaltheme?.palette?.basic?.white,
        color: globaltheme?.palette?.primary?.main,
      },
    },
    subtleSecondary: {
      backgroundColor: globaltheme?.palette?.basic?.white,
      color: globaltheme?.palette?.secondary?.shade_400,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.primary?.shade_100,
        color: globaltheme?.palette?.primary?.main,
      },
      '&:disabled': {
        color: globaltheme?.palette?.primary?.main,
        backgroundColor: globaltheme?.palette?.basic?.white,
      },
    },
    link: {
      backgroundColor: globaltheme?.palette?.basic?.white,
      color: globaltheme?.palette?.persian_blue?.main,
      '&:hover': {
        backgroundColor: globaltheme?.palette?.basic?.white,
        textDecoration: 'underline',
      },
      '&:disabled': {
        color: globaltheme?.palette?.persian_blue?.main,
      },
    },
    thin: {
      padding: '0 12px',
      height: '32px',
      minWidth: '95px',
      fontSize: '14px',
    },
    small: {
      padding: '0 13px',
      height: '40px',
      minWidth: '107px',
    },
    medium: {
      padding: '0 13px',
      height: '42px',
      minWidth: '107px',
    },
    large: {
      padding: '0 21px',
      height: '46px',
      minWidth: '107px',
    },
    loaderWrapper: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      height: ({ size }: IStyles) => (size === 'large' ? '24px' : '20px'),
    },
    loader: {
      color: ({ color }: IStyles) =>
        ({
          primary: globaltheme?.palette?.basic?.white,
          secondary: globaltheme?.palette?.primary?.main,
          tertiary: globaltheme?.palette?.secondary?.main,
          success: globaltheme?.palette?.basic?.white,
          danger: globaltheme?.palette?.basic?.white,
          warning: globaltheme?.palette?.basic?.white,
          subtlePrimary: globaltheme?.palette?.primary?.main,
          subtleSecondary: globaltheme?.palette?.secondary?.shade_400,
          link: globaltheme?.palette?.persian_blue?.main,
        }[color]),
    },
    hide: {
      visibility: 'hidden',
    },
  }),
  { name: 'ComponentLibrary' }
);

interface ButtonProps extends Omit<MuiButtonProps, 'color' | 'size'> {
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'subtlePrimary'
    | 'subtleSecondary'
    | 'link'
    | null;
  size?: 'thin' | 'small' | 'medium' | 'large' | null;
  className?: string;
  buttonWrapperClassName?: string;
  progressWrapperClassName?: string;
  progressClassName?: string;
  loaderHiddenComponentClassName: string;
  progressProps?: any;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  color = 'primary',
  size = 'medium',
  buttonWrapperClassName,
  className,
  progressWrapperClassName,
  progressClassName,
  loaderHiddenComponentClassName,
  progressProps,
  loading,
  disabled,
  children,
  ...restProps
}) => {
  const { fullWidth } = restProps;
  const classes = useStyles({
    disabled: disabled || loading,
    loading,
    fullWidth,
    size,
    color,
  });

  return (
    <div className={classnames(classes.buttonWrapper, buttonWrapperClassName)}>
      <MuiButton
        className={classnames(
          classes.button,
          color && classes[color],
          size && classes[size],
          className
        )}
        disabled={disabled || loading}
        {...restProps}
      >
        {loading && (
          <>
            <Box
              className={classnames(
                classes.loaderWrapper,
                progressWrapperClassName
              )}
            >
              <CircularProgress
                className={classnames(classes.loader, progressClassName)}
                size={size === 'large' ? 24 : 20}
                {...progressProps}
              />
            </Box>
            <div
              className={classnames(
                classes.hide,
                loaderHiddenComponentClassName
              )}
            >
              {children}
            </div>
          </>
        )}
        {!loading && children}
      </MuiButton>
    </div>
  );
};

export default withGenerateClassName(Button);
