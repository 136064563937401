import { FC } from 'react';
import MuiBadge, { BadgeProps as MuiBadgeProps } from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globaltheme: any) => ({
    badge: {
      padding: globaltheme.spacing(0, '6px'),
      borderRadius: globaltheme.spacing(3),
      height: globaltheme.spacing(5),
      fontSize: globaltheme.spacing(4),
    },
    primary_standard: {
      color: globaltheme?.palette?.basic?.white,
      backgroundColor: globaltheme?.palette?.primary?.main,
    },
    primary_inverted: {
      color: globaltheme?.palette?.primary?.main,
      backgroundColor: globaltheme?.palette?.basic?.white,
    },
    secondary_standard: {
      color: globaltheme?.palette?.secondary?.main,
      backgroundColor: globaltheme?.palette?.secondary?.shade_200,
    },
    secondary_inverted: {
      color: globaltheme?.palette?.secondary?.shade_200,
      backgroundColor: globaltheme?.palette?.secondary?.main,
    },
    coral_red_standard: {
      color: globaltheme?.palette?.basic?.danger_red,
      backgroundColor: globaltheme?.palette?.coral_red?.shade_100,
    },
    coral_red_inverted: {
      // it is inverted of coral_red but light color is different
      color: globaltheme?.palette?.basic?.white,
      backgroundColor: globaltheme?.palette?.basic?.danger_red,
    },
    fine_pine_standard: {
      color: globaltheme?.palette?.fine_pine?.shade_800,
      backgroundColor: globaltheme?.palette?.fine_pine?.shade_200,
    },
    fine_pine_inverted: {
      color: globaltheme?.palette?.fine_pine?.shade_200,
      backgroundColor: globaltheme?.palette?.fine_pine?.shade_800,
    },
  }),
  { name: 'ComponentLibrary' }
);

interface BadgeProps extends Omit<MuiBadgeProps, 'color' | 'variant'> {
  color?: 'primary' | 'secondary' | 'coral_red' | 'fine_pine';
  variant?: 'standard' | 'inverted';
  badgeClassName?: string;
}

const Badge: FC<BadgeProps> = ({
  children,
  color,
  variant,
  badgeClassName,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <MuiBadge
      classes={{
        badge: [
          classes.badge,
          color && variant && classes[`${color}_${variant}`],
          badgeClassName,
        ].join(' '),
      }}
      {...restProps}
    >
      {children}
    </MuiBadge>
  );
};

export default withGenerateClassName(Badge);
