import MuiAvatar, {
  AvatarProps as MuiAvatarProps,
} from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globalTheme: any) => ({
    root: {
      fontWeight: 500,
      height: '32px',
      width: '32px',
    },
    medium: {
      fontSize: '12px',
    },
    large: {
      fontSize: '14px',
    },
    color0: {
      backgroundColor: '#B9B2E6',
      color: '#4A3AB1',
    },
    color1: {
      backgroundColor: '#BBEBF5',
      color: '#35AAC4',
    },
    color2: {
      backgroundColor: '#CCE0FF',
      color: '#0052CC',
    },
    color3: {
      backgroundColor: '#F8C8DF',
      color: '#ED0D78',
    },
    color4: {
      backgroundColor: '#F7D6D4',
      color: '#DF645D',
    },
    color5: {
      backgroundColor: '#D3F3D2',
      color: '#18B915',
    },
    color6: {
      backgroundColor: '#CCFFEE',
      color: '#00B77A',
    },
    color7: {
      backgroundColor: '#BFFFEA',
      color: '#0A7451',
    },
    color8: {
      backgroundColor: '#FFEECC',
      color: '#FFAA00',
    },
    color9: {
      backgroundColor: '#ECCFF0',
      color: '#A409BD',
    },
  }),
  { name: 'ComponentLibrary' }
);
const getAvatarLabel = (label: any, processInitials: boolean = true) => {
  if (!label) return '';

  const splittedLabel = label.split(' ');
  if (!processInitials) return label.slice(0, 3).toUpperCase();

  if (splittedLabel.length > 1)
    return (
      splittedLabel[0].toUpperCase().charAt(0) +
      splittedLabel[1].toUpperCase().charAt(0)
    );
  return splittedLabel[0].toUpperCase().charAt(0);
};

interface AvatarProps extends MuiAvatarProps {
  label?: string;
  processInitials: boolean;
  icon?: React.ElementType;
  colorIndex?: number;
  fontSize?: 'medium' | 'large';
}

type ColorType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
const Avatar: FC<AvatarProps> = ({
  label,
  icon,
  colorIndex,
  className,
  fontSize = 'medium',
  processInitials = true,
  ...restProps
}) => {
  const classes = useStyles();
  const [avatarColorIndex, setAvatarColorIndex] = useState<ColorType>(
    (colorIndex !== undefined ? colorIndex % 10 : 0) as ColorType
  );

  useEffect(() => {
    setAvatarColorIndex(
      (colorIndex !== undefined
        ? colorIndex % 10
        : Math.floor(Math.random() * 10)) as ColorType
    );
  }, [colorIndex]);

  return (
    <MuiAvatar
      className={[
        classes.root,
        label && classes[`color${avatarColorIndex}`],
        classes[fontSize],
        className,
      ].join(' ')}
      {...restProps}
    >
      {getAvatarLabel(label, processInitials) || icon}
    </MuiAvatar>
  );
};

export default withGenerateClassName(Avatar);
