import { FC } from 'react';

import { Link, LinkProps as MuiLinkProps } from '@material-ui/core';

import { Option } from '../Breadcrumb';
import { useStyles } from './BreadcrumbLink.styles';

import classnames from 'classnames';
import withGenerateClassName from '../../../../themes/withGenerateClassName';

interface BreadcrumbLinkProps extends MuiLinkProps {
  size?: 'medium' | 'large';
  active?: boolean;
  option: Option;
  linkClassName?: any;
}

const BreadcrumbLink: FC<BreadcrumbLinkProps> = ({
  size,
  option,
  active,
  linkClassName,
  ...restProps
}) => {
  const classes = useStyles({ size });

  return (
    <Link
      classes={{
        root: classnames(classes.linkRoot, linkClassName),
        underlineAlways: classes.underlineAlways,
        underlineHover: classes.underlineHover,
      }}
      href={option.href}
      onClick={(e) => option.onClick?.(e, option)}
      className={option.className}
      underline={active ? 'always' : 'hover'}
      {...restProps}
    >
      {option.icon}
      {option.label}
    </Link>
  );
};

export default withGenerateClassName(BreadcrumbLink);
