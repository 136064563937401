import { FC } from 'react';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox';

import useStyles from './Checkbox.styles';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface CheckboxProps extends Omit<MuiCheckboxProps, 'size'> {
  size: 'small' | 'medium' | 'large' | 'extra_large';
  error?: boolean;
  wrapperClassName?: string;
  checkboxClassName?: string;
  borderColor?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  size = 'extra_large',
  error,
  wrapperClassName,
  checkboxClassName,
  ...restProps
}) => {
  const { color, borderColor } = restProps;
  const { disabled, checked } = restProps;
  const classes = useStyles({ error, disabled, checked, color, borderColor });

  const icon = <></>;
  const checkedIcon = (
    <CheckBoxRoundedIcon viewBox="3 3 18 18" preserveAspectRatio="none" />
  );
  const indeterminateIcon = (
    <IndeterminateCheckBoxIcon viewBox="3 3 18 18" preserveAspectRatio="none" />
  );

  return (
    <div
      className={[
        classes.checkboxWrapper,
        size && classes[size],
        wrapperClassName,
      ].join(' ')}
    >
      <MuiCheckbox
        icon={icon}
        checkedIcon={checkedIcon}
        indeterminateIcon={indeterminateIcon}
        classes={{
          root: [classes.root, checkboxClassName].join(' '),
          disabled: classes.disabled,
        }}
        disableRipple
        color="default"
        {...restProps}
      />
    </div>
  );
};

export default withGenerateClassName(Checkbox);
