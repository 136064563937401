import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionSummary, {
  AccordionSummaryProps,
} from '@material-ui/core/AccordionSummary';
import AccordionDetails, {
  AccordionDetailsProps,
} from '@material-ui/core/AccordionDetails';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import classnames from 'classnames';

interface IStyle {
  expandIconPosition: 'start' | 'end';
}

const useStyles = makeStyles(
  (theme: any) => ({
    accordionRoot: {
      boxShadow: '0px 3px 0px rgba(14, 13, 12, 0.14)',
      borderRadius: '12px',
      '&:before': {
        content: 'none !important',
      },
    },
    accordionSummaryRoot: {
      color: theme?.palette?.secondary?.main,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      height: '51px',
      boxSizing: 'border-box',
      minHeight: '52px',
      padding: '0 8.33px',
      flexDirection: ({ expandIconPosition }: IStyle) =>
        expandIconPosition === 'start' ? 'row-reverse' : 'row',
      '&$accordionSummaryExpanded': {
        minHeight: 'inherit', // Set the same height for expanded state
      },
    },
    accordionSummaryExpanded: {
      alignItems: 'center',
    },
    expandIcon: {
      transform: 'rotate(-90deg)',
      padding: '0',
      marginRight: '0',
    },
    accordionDetailsRoot: {
      display: 'block',
      padding: '0',
      borderRadius: '0 0 12px 12px',
      overflow: 'hidden',
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    },
  }),
  { name: 'ComponentLibrary' }
);

interface IAccordion extends AccordionProps {
  AccordionSummaryProps?: AccordionSummaryProps;
  AccordionDetailsProps?: AccordionDetailsProps;
  accordionSummaryContent: React.ReactNode;
  expandIconPosition: 'start' | 'end';
  rootClassName?: string;
  summaryClassName?: string;
  expandIconClassName?: string;
  expandedSummaryClassName?: string;
  collapsedContentClassName?: string;
  accordionClasses?: any;
  accordionSummaryClasses?: any;
  accordionDetailsClasses?: any;
}

const Accordion: React.FC<IAccordion> = ({
  AccordionSummaryProps,
  AccordionDetailsProps,
  accordionSummaryContent,
  expandIconPosition,
  children,
  rootClassName,
  summaryClassName,
  expandIconClassName,
  expandedSummaryClassName,
  collapsedContentClassName,
  accordionClasses,
  accordionSummaryClasses,
  accordionDetailsClasses,
  ...restProps
}) => {
  const {
    accordionRoot,
    accordionSummaryRoot,
    expandIcon,
    accordionDetailsRoot,
    accordionSummaryExpanded,
  } = useStyles({ expandIconPosition });

  return (
    <MuiAccordion
      classes={{
        root: classnames(accordionRoot, rootClassName),
        ...accordionClasses,
      }}
      square
      {...restProps}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownRoundedIcon />}
        classes={{
          root: classnames(accordionSummaryRoot, summaryClassName),
          expandIcon: classnames(expandIcon, expandIconClassName),
          expanded: classnames(
            accordionSummaryExpanded,
            expandedSummaryClassName
          ),
          ...accordionSummaryClasses,
        }}
        {...AccordionSummaryProps}
      >
        {accordionSummaryContent}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classnames(accordionDetailsRoot, collapsedContentClassName),
          ...accordionDetailsClasses,
        }}
        {...AccordionDetailsProps}
      >
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default withGenerateClassName(Accordion);
