import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme: any) => ({
    navbar__root: {
      boxShadow: '0px -4px 4px rgba(141, 145, 160, 0.13)',
      background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFBFE',
      height: 'auto',
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    navbar__fixed_bottom: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
    },
    navlink__root: {
      position: 'relative',
      overflow: 'hidden',
      paddingBottom: '16px',
      paddingTop: '18px !important',
      fontWeight: 300,
      minWidth: 0,
    },
    navlink__selected: {
      fontWeight: 500,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '8px',
        background: theme?.palette?.primary?.main,
        borderRadius: '0 0 2px 2px',
      },
    },
    navlink__wrapper: {
      gap: '6px',
    },
    navlink__label: {
      fontSize: '11px !important',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
  }),
  { name: 'ComponentLibrary', index: 2 }
);
