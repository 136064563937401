import MuiBottomNavigation, {
  BottomNavigationProps as MuiBottomNavigationProps,
  // BottomNavigationAction as MuiBottomNavigationAction,
  // BottomNavigationActionProps as MuiBottomNavigationActionProps,
} from '@material-ui/core/BottomNavigation';
import MuiBottomNavigationAction, {
  BottomNavigationActionProps as MuiBottomNavigationActionProps,
} from '@material-ui/core/BottomNavigationAction';
import classnames from 'classnames';

import useStyles from './BottomNavigation.styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import InteractiveTooltip from '../InteractiveTooltip/InteractiveTooltip';

// Extend MuiBottomNavigationActionProps to include tooltipTitle
interface CustomBottomNavigationActionProps
  extends MuiBottomNavigationActionProps {
  tooltipTitle?: string;
  id: string;
}

interface IBottomNavigationProps extends MuiBottomNavigationProps {
  items: CustomBottomNavigationActionProps[];
  navbar_classname?: string;
  fixed_bottom?: boolean;
  open?: boolean;
  onClose?: Function;
  tooltipClasses?: string;
  tooltipRootClassname?: string;
  activeId?: string;
  onPrimaryClick: Function;
  lastElementClick: Function;
  sx: Object;
  customFooter: Function;
}

function BottomNavigation({
  items = [],
  navbar_classname,
  fixed_bottom,
  open,
  onClose,
  tooltipClasses,
  tooltipRootClassname,
  activeId,
  onPrimaryClick,
  lastElementClick,
  sx,
  children,
  customFooter,
  ...rest_props
}: IBottomNavigationProps) {
  const classes = useStyles();

  return (
    <MuiBottomNavigation
      classes={{
        root: classnames(
          classes.navbar__root,
          fixed_bottom && classes.navbar__fixed_bottom,
          navbar_classname
        ),
      }}
      showLabels
      {...rest_props}
    >
      {children}
      {items.map((item) => (
        <InteractiveTooltip
          onCloseClick={onClose}
          open={open && activeId === item.id}
          title={item.tooltipTitle}
          showPrimaryBtn={false}
          customFooter={customFooter}
          tooltipClasses={activeId === item.id && tooltipClasses}
          totalSteps={0}
          enterDelay={0}
          leaveDelay={0}
          placement={'top'}
        >
          <MuiBottomNavigationAction
            classes={{
              root: classnames(
                classes.navlink__root,
                open && activeId === item.id && tooltipRootClassname
              ),
              selected: classnames(classes.navlink__selected),
              wrapper: classnames(classes.navlink__wrapper),
              label: classnames(classes.navlink__label),
            }}
            {...item}
          />
        </InteractiveTooltip>
      ))}
    </MuiBottomNavigation>
  );
}

export default withGenerateClassName(BottomNavigation);
