import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  disabled?: boolean;
  error?: boolean;
  checked?: boolean;
  color?: string;
  borderColor?: string;
}

const useStyles = makeStyles(
  (globaltheme: any) => ({
    checkboxWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '3px',
      background: ({ checked }: StyleProps) =>
        checked ? globaltheme?.palette?.basic?.white : '#F4F5F7',
      border: ({ error, checked, borderColor }: StyleProps) => {
        if (error)
          return `1.5px solid ${globaltheme?.palette?.basic?.danger_red}`;
        if (checked) return 'none';
        return `1.5px solid ${
          borderColor || globaltheme?.palette?.secondary?.shade_200
        }`;
      },
      '& svg': {
        color: ({ error, color }) =>
          error
            ? globaltheme?.palette?.basic?.danger_red
            : color || globaltheme?.palette?.primary?.main,
      },
      '&:hover': {
        border: ({ borderColor }: StyleProps) =>
          `1.5px solid ${
            borderColor || globaltheme?.palette?.persian_blue?.shade_300
          }`,
        background: ({ checked }: StyleProps) =>
          checked ? globaltheme?.palette?.basic?.white : '#E9E9E9',
      },
      cursor: ({ disabled }: StyleProps) =>
        disabled ? 'not-allowed' : 'pointer',
      opacity: ({ disabled }: StyleProps) => (disabled ? 0.5 : 1),
    },
    root: {
      padding: globaltheme.spacing(0),
      borderRadius: globaltheme.spacing(1),
      color: ({ color }) => color || globaltheme?.palette?.primary?.main,
    },
    disabled: {
      opacity: '0.5',
    },
    small: {
      width: '10px',
      height: '10px',
      boxSizing: 'border-box',
      '& span,& svg, & input': {
        width: globaltheme.spacing(3),
        height: globaltheme.spacing(3),
      },
    },
    medium: {
      width: globaltheme.spacing(4),
      height: globaltheme.spacing(4),
      boxSizing: 'border-box',
      '& span,& svg, & input': {
        width: '10px',
        height: '10px',
      },
    },
    large: {
      width: globaltheme.spacing(5),
      height: globaltheme.spacing(5),
      boxSizing: 'border-box',
      '& span,& svg, & input': {
        width: '14px',
        height: '14px',
      },
    },
    extra_large: {
      width: globaltheme.spacing(6),
      height: globaltheme.spacing(6),
      boxSizing: 'border-box',
      '& span,& svg, & input': {
        width: '22px',
        height: '22px',
      },
    },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
