import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme: any) => ({
    root: {
      '& li': {
        background: 'none',
        fontFamily: theme?.typography?.fontFamily,
      },
    },
  }),
  { name: 'ComponentLibrary' }
);
