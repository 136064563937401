import React, { FC } from 'react';

import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@material-ui/core';

import withGenerateClassName from '../../../themes/withGenerateClassName';
import { useStyles } from './Breadcrumb.styles';
import BreadcrumbLink from './component/BreadcrumbLink';

import classnames from 'classnames';

export interface Option {
  label: string;
  href?: string;
  icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>, option: Option) => void;
  customComponent?: (option: Option) => React.ReactNode;
  className?: any;
}

interface BreadcrumbProps extends MuiBreadcrumbsProps {
  options: Option[];
  size?: 'medium' | 'large';
  className?: any;
  linkClassName?: any;
}

const Breadcrumb: FC<BreadcrumbProps> = ({
  options,
  size = 'medium',
  className,
  linkClassName,
  ...restProps
}) => {
  const classes = useStyles();

  const isActive = (index: number) => index === options.length - 1;

  return (
    <MuiBreadcrumbs
      className={classnames(classes.root, className)}
      {...restProps}
    >
      {options.map((option, index) =>
        option.customComponent ? (
          <div key={index}>{option.customComponent(option)}</div>
        ) : (
          <BreadcrumbLink
            key={index}
            linkClassName={linkClassName}
            option={option}
            size={size}
            active={isActive(index)}
          />
        )
      )}
    </MuiBreadcrumbs>
  );
};

export default withGenerateClassName(Breadcrumb);
