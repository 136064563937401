import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  backgroundShadow?: boolean;
  isDesktop?: boolean;
  // error?: boolean;
  // fullWidth?: boolean;
  // width?: any;
  // offeringWrapWidth?: any;
}

const useStyles = makeStyles(
  (theme: any) => ({
    carousalMainContainer: {
      backgroundColor: '#ffffff',
      maxWidth: '1300px',
      width: '100%',
      margin: 'auto',
      border: ({ backgroundShadow, isDesktop }: StyleProps) =>
        !backgroundShadow || !isDesktop ? 'none' : '1.5px solid #E7E6E4',
      borderRadius: '12px',
      boxSizing: 'border-box',
      boxShadow: ({ backgroundShadow, isDesktop }: StyleProps) =>
        !backgroundShadow || !isDesktop
          ? 'none'
          : '0px 3px 6px rgba(14, 13, 12, 0.12)',
    },
    carousalWrapper: {
      padding: ({ backgroundShadow, isDesktop }: StyleProps) =>
        isDesktop ? '15px' : '0px',
      margin: 'auto 30px',
    },
    slickNextNew: {
      content: '',
      display: 'inline-block',
      height: '15px',
      width: '15px',
      borderTop: 'solid 2px #4A3AB1',
      borderLeft: 'solid 2px #4A3AB1',
      transform: 'rotate(135deg) translateY(4px)',
      right: '-15px',
      top: ({ backgroundShadow, isDesktop }: StyleProps) =>
        isDesktop ? '190px' : '290px',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '22px',
    },
    slicksPadding: {
      paddingRight: ({ backgroundShadow, isDesktop }: StyleProps) =>
        isDesktop ? '35px' : '12%',
    },
    dots: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      position: 'relative',
      top: '15px',
    },
    arrowBackgroundWrapper: {
      backgroundColor: 'grey',
      padding: '8px',
      borderRadius: '50%',
    },
    slickPrevNew: {
      content: '> !important',
      color: 'black',
      fontSize: '40px',
      display: 'inline-block',
      height: '15px',
      width: '15px',
      borderTop: 'solid 2px #4A3AB1',
      borderLeft: 'solid 2px #4A3AB1',
      transform: 'rotate(315deg) translateY(4px)',
      left: '-15px',
      top: ({ backgroundShadow, isDesktop }: StyleProps) =>
        isDesktop ? '190px' : '290px',
    },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
