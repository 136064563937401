import Paper, { PaperProps as MuiPaperProps } from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globaltheme: any) => ({
    root: {
      borderRadius: globaltheme.spacing(4),
    },
    outlined: {
      border: `1.5px solid ${globaltheme?.palette?.secondary?.shade_100}`,
      boxSizing: 'border-box',
    },
    shadow: {
      boxShadow: '0px 3px 6px rgba(14, 13, 12, 0.12)',
    },
    shadow_sm: {
      boxShadow: '0px 3px 6px rgba(14, 13, 12, 0.08)',
    },
    shadow_md: {
      boxShadow: '0px 3px 0px rgba(14, 13, 12, 0.14)',
    },
    shadow_lg: {
      boxShadow: '0px 3px 6px rgba(14, 13, 12, 0.16)',
    },
    shadow_xl: {
      boxShadow: '0px 3px 6px rgba(14, 13, 12, 0.32)',
    },
    none: {
      boxShadow: 'none',
    },
  }),
  { name: 'ComponentLibrary' }
);

interface CardProps extends MuiPaperProps {
  variant?: 'outlined';
  shadow?: 'shadow' | 'shadow_sm' | 'shadow_md' | 'shadow_lg' | 'none';
  CardClassName?: any;
  className?: any;
}

const Card: FC<CardProps> = ({
  shadow = 'shadow',
  variant,
  CardClassName,
  className,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={classnames(
        classes.root,
        classes[shadow],
        variant && classes[variant],
        CardClassName,
        className
      )}
      {...restProps}
    />
  );
};

export default withGenerateClassName(Card);
